.section {
  padding: 40px 0;

  @include breakpoint(md) {
    padding: 80px 0;
  }

  &:first-child {
    padding-top: 0;
  }

  &--no-padding {
    padding: 0;
  }

  &--no-space-before {
    margin-top: -40px;

    @include breakpoint(md) {
      margin-top: -80px;
    }
  }

  &--headline {
    margin-bottom: -20px;
    padding-bottom: 0;
  }

  &--full {
    display: flex;
    min-height: calc(100vh - 380px);
  }

  &--grey {
    background-color: $background-grey;
  }
}
