.row {
  display: grid;
  grid-row-gap: 20px;
  margin-bottom: 20px;

  @include breakpoint(lg) {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 0 20px;
  }

  &--row1 {
    @include breakpoint(lg) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  &--row2 {
    @include breakpoint(lg) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &--row3 {
    @include breakpoint(lg) {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &--row4 {
    @include breakpoint(lg) {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  &--first-small {
    @include breakpoint(lg) {
      grid-template-columns: fit-content(200px) repeat(2, 1fr);
    }
  }
}
