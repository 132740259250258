.range {
  appearance: none;
  width: 100%; /* Full-width */
  height: 8px; /* Specified height */
  background-color: rgba($green-light, 23%);
  outline: none; /* Remove outline */
  transition: opacity 0.2s;
  flex: 1;
}

/* Mouse-over effects */
.range:hover {
  opacity: 1; /* Fully shown on mouse-over */
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.range::-webkit-slider-thumb {
  appearance: none;
  width: 25px; /* Set a specific slider handle width */
  height: 25px; /* Slider handle height */
  background: $green-light; /* Green background */
  cursor: pointer; /* Cursor on hover */
  border-radius: 100%;
}
