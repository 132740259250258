.text {
  font-size: $font-s;

  &--big {
    font-size: $font-m;
    margin-bottom: 20px;
  }

  &--grey {
    color: $grey;
  }
}
