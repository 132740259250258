.headline {
  font-weight: normal;

  &--level1 {
    margin-bottom: 30px;
    font-size: $m-font-xxl;

    @include breakpoint(md) {
      font-size: $font-xxl;
    }
  }

  &--level2 {
    font-size: $font-l;
    font-weight: 100;
    margin-bottom: 30px;

    @include breakpoint(md) {
      font-size: $font-xl;
    }
  }

  &--level3 {
    font-size: $font-l;
    font-weight: 100;
    margin-bottom: 20px;
  }

  &--level4 {
    font-size: $font-m;
    margin-bottom: 20px;
  }

  &--bold {
    font-weight: 700;
  }

  &--white {
    color: $white;
  }

  &--green {
    color: $green-dark;
  }

  &--center {
    text-align: center;
  }

  &--no-margin {
    margin: 0;
  }
}
