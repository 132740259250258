.navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(255 255 255 / 90%);
  border-radius: 0 0 16px 16px;
  padding: 16px 20px;
  margin: 0 -20px;
  box-shadow: $box-box-shadow;

  @include breakpoint(md) {
    margin: 0 -20px;
    border-radius: 16px;
  }

  &__wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;

    @include breakpoint(md) {
      top: 35px;
    }
  }

  &__menu-wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    display: none;
    flex-flow: column;
    justify-content: space-between;
    width: 100%;
    background-color: white;
    padding: 100px 25px 25px;
    transform: translateX(100%);

    @include breakpoint(lg) {
      position: relative;
      z-index: auto;
      display: flex;
      flex-flow: row;
      background-color: transparent;
      padding: 0;
      transform: translateX(0);
    }

    &.active {
      display: flex;
      transform: translateX(0);
    }

    &.fade-in {
      display: flex;
      animation-name: in;
      animation-duration: 0.5s;
    }

    &.fade-out {
      display: flex;
      animation-name: in;
      animation-duration: 0.5s;
      animation-direction: reverse;
    }

    @keyframes in {
      from {
        transform: translateX(100%);
      }

      to {
        transform: translateX(0);
      }
    }
  }

  &__legal {
    display: flex;
    padding-bottom: 0;

    @include breakpoint(lg) {
      display: none;
    }
  }

  &__legal-container {
    place-content: center;
  }

  &__legal-link {
    font-size: $m-font-s;
  }

  &__item-wrapper {
    display: flex;
    flex-flow: column;
    align-items: center;

    @include breakpoint(lg) {
      flex-flow: row;
    }

    :not(:last-child) {
      margin-bottom: 30px;

      @include breakpoint(lg) {
        margin-bottom: 0;
      }
    }
  }

  &__info-wrapper {
    display: flex;
    align-items: center;
    margin-left: auto;
  }

  &__item {
    color: black;
    font-size: $m-font-m;

    @include breakpoint(lg) {
      font-size: $font-s;

      &:not(:last-child) {
        margin-right: 40px;
      }
    }
  }

  &__logo-wrapper {
    @include breakpoint(md) {
      height: 40px;
    }
  }

  &__logo {
    width: 180px;
    margin-right: 30px;

    @include breakpoint((min: 900px)) {
      width: auto;
      height: 100%;
      margin-right: 80px;
    }
  }

  &__button {
    width: 100%;
    justify-content: center;

    @include breakpoint(lg) {
      width: auto;
    }
  }

  &__telefon {
    margin-right: 40px;

    @include breakpoint((max: 1350px)) {
      display: none !important;
    }
  }

  &__telefon-button {
    margin-right: 25px;

    @include breakpoint(md) {
      display: none;
    }
  }

  &__menu-button {
    @include breakpoint(lg) {
      display: none;
    }
  }

  &__mobile-buttons-wrapper {
    display: flex;
    align-items: center;

    @include breakpoint(lg) {
      display: none;
    }
  }
}
