.vorteile {
  background-color: $white;

  &__grid {
    grid-template-columns: 1fr;
    gap: 10px;

    @include breakpoint(md) {
      grid-template-columns: repeat(2, 1fr);
      gap: 40px;
    }

    @include breakpoint(xl) {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  &__head {
    display: flex;
    justify-content: center;
    margin-bottom: 60px;
  }

  &__item {
    display: flex;
    flex-flow: row;
    align-items: center;
    background-color: $background-grey;
    border-radius: 20px;
    padding: 20px;
    width: 100%;
    height: 100%;

    @include breakpoint(md) {
      flex-flow: column;
      padding: 40px;
    }
  }

  &__icon-wrapper {
    display: flex;
    justify-content: center;
    background-color: $white;
    border-radius: 100%;
    margin-bottom: 0;
    width: 100px;
    height: 100px;

    @include breakpoint(md) {
      margin-bottom: 40px;
      width: 160px;
      height: 160px;
    }
  }

  &__icon {
    align-items: center;
    object-fit: scale-down;
  }

  &__headline {
    font-size: $m-font-sm;
    text-align: left;
    padding-left: 20px;
    flex-grow: 1;
    margin-bottom: 10px;

    @include breakpoint(md) {
      font-size: $m-font-m;
      text-align: center;
      flex-grow: 0;
      padding: 0;
    }
  }

  &__text-wrapper {
    width: 100%;
    flex: 1;
  }

  &__text {
    text-align: left;
    padding-left: 20px;

    @include breakpoint(md) {
      text-align: center;
      padding-left: 0;
    }
  }
}
