.link-list-image {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
  max-width: 600px;
  margin-right: auto;
  margin-left: auto;

  @include breakpoint(md) {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 25px;
    max-width: none;
  }

  &__item {
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 20px;
    height: 100px;
    color: $white;
    font-size: $m-font-m;
    font-weight: 300;
    padding: 50px;

    @include breakpoint(sm) {
      height: 200px;
      font-size: $font-m;
    }

    @include breakpoint(md) {
      height: 300px;
    }
  }

  &__background {
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    filter: brightness(70%);
  }
}
