.beitragsrechner {
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;

  @include breakpoint(md) {
    grid-template-columns: 1fr 2fr;
  }

  &__content {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: flex-start;
    max-width: 100%;
    padding-bottom: 20px;

    @include breakpoint(md) {
      padding-top: 20px;
      padding-right: 20px;
      padding-bottom: 0;
    }
  }

  &__text {
    font-size: $font-s;
    margin-bottom: 20px;

    @include breakpoint(xl) {
      margin-bottom: 0;
    }
  }

  &-rechner {
    box-shadow: $box-box-shadow;
    border-radius: 16px;
    padding: 35px 30px;
    max-width: 100%;

    @include breakpoint(xl) {
      padding: 65px 50px;
    }

    &__slide-wrapper {
      display: grid;
      grid-template-areas: "bis value" "slider slider";
      margin-bottom: 20px;
      font-size: $font-l;
      color: $green-light;

      @include breakpoint(xl) {
        grid-template-columns: 1fr 4fr 3fr;
        grid-template-areas: "bis slider value";
      }
    }

    &__bis {
      grid-area: bis;
      font-size: 18px;

      @include breakpoint(sm) {
        font-size: 24px;
      }
    }

    &__slider {
      grid-area: slider;
      align-self: center;
      margin-top: 10px;

      @include breakpoint(xl) {
        margin: auto 0;
      }
    }

    &__slide-value {
      color: $green-light;
      white-space: nowrap;
      text-align: right;
      grid-area: value;
      font-size: $font-s;

      @include breakpoint(sm) {
        font-size: $m-font-m;
      }

      @include breakpoint(xl) {
        font-size: $font-m;
      }
    }

    &__headline {
      margin-bottom: 25px;
      font-size: 18px;

      @include breakpoint(sm) {
        font-size: $m-font-m;
      }

      @include breakpoint(xl) {
        margin-bottom: 45px;
        font-size: $font-m;
      }
    }

    &__result-wrapper {
      display: flex;
      justify-content: space-between;
    }

    &__result-text {
      margin-right: 10px;
      font-size: 18px;

      @include breakpoint(sm) {
        font-size: 24px;
      }
    }

    &__result-number {
      font-weight: bold;
      white-space: nowrap;
      align-self: center;
      font-size: 18px;

      @include breakpoint(sm) {
        font-size: 24px;
      }
    }
  }
}
