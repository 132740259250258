.redactor {
  a {
    @include arrow_suffix;
  }

  h1 {
    margin-bottom: 30px;
    font-size: $m-font-xl;

    @include breakpoint(md) {
      font-size: $font-l;
    }
  }

  h2 {
    font-size: $font-m;
    font-weight: 100;
    margin-bottom: 30px;

    @include breakpoint(md) {
      font-size: $font-m;
    }
  }

  h3 {
    font-size: $font-m;
    font-weight: 100;
    margin-bottom: 20px;
  }

  h4 {
    font-size: $font-m;
    margin-bottom: 20px;
  }

  ul {
    margin-bottom: 20px;
  }

  p {
    margin-bottom: 20px;
  }
}
