.image {
  display: flex;


  &__img {
    width: 100%;
    max-width: 100%;
    height: auto;

    @include breakpoint(md) {
      max-height: none;
      margin-top: 0;
    }

    @include breakpoint(lg) {
      width: 100%;
      max-width: 100%;
      max-height: none;
    }
  }
}

