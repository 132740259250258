.header-small {
  position: relative;
  display: flex;
  align-items: flex-end;
  height: 200px;
  margin-bottom: 80px;
  overflow: hidden;

  @include breakpoint(md) {
    height: 300px;
  }

  &__image {
    position: absolute;
    top: -5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
    z-index: -1;
    width: calc(100% + 10px);
    height: calc(100% + 10px);
    filter: blur(2px);
    object-fit: cover;
  }
}
