.numerical-enumeration {
  background-color: $white;

  &__container {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 20px;
    padding-top: 80px;
    padding-bottom: 80px;

    @include breakpoint(lg) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &__headline {
    font-size: 26px;
    margin-bottom: 30px;

    @include breakpoint(sm) {
      font-size: 30px;
    }

    @include breakpoint(lg) {
      font-size: $font-l;
    }
  }

  &__number {
    display: inline;
    font-size: 36px;

    @include breakpoint(sm) {
      font-size: 45px;
    }

    @include breakpoint(lg) {
      font-size: $font-xl;
    }
  }

  &__image-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 25px;
    grid-row: 1;

    @include breakpoint(lg) {
      grid-row: auto;
    }
  }

  &__image {
    width: 80px;

    @include breakpoint(sm) {
      width: 100px;
    }

    @include breakpoint(lg) {
      width: 120px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    margin: 0 auto;

    @include breakpoint(sm) {
      max-width: 450px;
    }

    @include breakpoint(lg) {
      max-width: none;
    }
  }

  &__button {
    width: 100%;
    margin-top: 25px;
    justify-content: center;

    @include breakpoint(lg) {
      width: auto;
      margin-top: 25px;
      align-self: flex-start;
    }
  }

  &--even {
    background-color: $background-grey;

    .numerical-enumeration {
      @include breakpoint(lg) {
        &__image-wrapper {
          grid-row: 1;
        }
      }
    }
  }

  &:first-child {
    .numerical-enumeration {
      &__container {
        padding-top: 0;
      }
    }
  }
}
