.banner {
  display: grid;
  grid-template-columns: 1fr ;
  grid-column-gap: 50px;

  @include breakpoint(md) {
    grid-template-columns: 1fr 1fr;
  }

  @include breakpoint(lg) {
    grid-template-columns: 1fr 2fr;
  }

  &__headline {
    font-size: 32px;
  }

  &__img {
    width: 100%;
    max-width: 100%;
    height: auto;
    object-fit: cover;
    margin-top: 25px;

    @include breakpoint(md) {
      max-height: none;
      margin-top: 0;
    }

    @include breakpoint(lg) {
      width: 100%;
      max-width: 100%;
      object-fit: cover;
    }
  }
}