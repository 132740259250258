// Desktop
$font-s: 18px;
$font-m: 32px;
$font-l: 40px;
$font-xl: 56px;
$font-xxl: 64px;

// Mobile
$m-font-s: 16px;
$m-font-sm: 20px;
$m-font-m: 24px;
$m-font-l: 26px;
$m-font-xl: 32px;
$m-font-xxl: 40px;
