.link-list-simple {
  margin-top: 40px;
  column-count: 1;
  column-gap: 16px;

  @include breakpoint(md) {
    margin-top: 96px;
    column-count: 2;
  }

  &__title {
    font-weight: 500;
    font-size: 18px;
  }

  &__headline {
    margin-bottom: 10px;
    font-size: 32px;

    @include breakpoint(md) {
      font-size: 40px;
    }
  }

  &__subline {
    font-size: 18px;
    font-weight: normal;

    @include breakpoint(md) {
      font-size: 32px;
    }
  }

  &__content {
    padding-top: 16px;
  }

  &__contentWrapper {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
    box-sizing: content-box;
  }

  &__item {
    position: relative;
    background-color: $background-grey;
    padding: 28px 32px;
    border-radius: 12px;
    color: $font-color;
    transition: box-shadow 0.2s;
    cursor: pointer;
    display: inline-block;
    margin: 0 0 1em;
    width: 100%;

    &::after {
      content: "";
      position: absolute;
      right: 30px;
      top: 30px;
      width: 20px;
      height: 20px;
      background-image: url("/images/lhv/icons/arrow-up.svg");
      background-position: center;
      background-repeat: no-repeat;
      transition: transform 0.5s;
    }

    &:hover {
      //background-color: white;
      //box-shadow: 2px 2px 3px 3px #0000001a;
    }

    &.active {
      &::after {
        transform: rotate(180deg);
      }
    }
  }
}
