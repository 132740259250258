.registrierungsformular {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 80px;
  height: 100%;

  @include breakpoint(lg) {
    grid-template-columns: 300px 1fr;
  }

  @include breakpoint(xl) {
    grid-template-columns: 450px 1fr;
  }

  &__loading {
    width: 100px;
    height: 100px;
    margin: 0 auto;
    animation: loading;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;

    img {
      width: 100%;
      height: 100%;
    }

    @keyframes loading {
      from {
        transform: rotate(0);
      }

      to {
        transform: rotate(360deg);
      }
    }
  }

  &-text {
    margin-bottom: 50px;
  }

  &-content {
    padding-bottom: 80px;
  }

  &-zusammenfassung {
    &__box {
      margin-bottom: 50px;
      border-radius: 12px;
      background-color: $background-grey;
      box-shadow: $box-box-shadow;
      padding: 30px;
    }

    &__list {
      & > div {
        margin-bottom: 10px;
      }
    }
  }

  &-sidebar {
    display: none;
    background-color: $background-grey;
    height: 100%;
    padding: 30px;
    border-radius: 20px 20px 0 0;

    @include breakpoint(lg) {
      display: block;
    }

    &__info {
      display: flex;
      align-items: center;
      color: #565656;
      font-size: 12px;
    }

    &__info-icon {
      margin-right: 10px;
    }

    &__item-wrapper {
      margin-bottom: 50px;
    }

    &__item {
      display: flex;
      padding: 12px 30px;
      margin-bottom: 20px;
      color: #588e19;

      @include breakpoint(lg) {
        padding: 12px 30px;
      }

      &.active,
      &:hover {
        background-color: white;
        border-radius: 12px;
        box-shadow: $box-box-shadow;
      }

      &[disabled] {
        opacity: 0.5;
        pointer-events: none;
      }
    }

    &__number {
      margin-right: 20px;
      font-weight: bold;
    }
  }

  &-form-control {
    display: flex;
    flex-direction: column;
    margin-top: 50px;

    @include breakpoint(md) {
      flex-direction: row;
    }

    &__buttons-right {
      display: flex;
      flex-direction: column;
      margin-top: 20px;

      @include breakpoint(md) {
        flex-direction: row;
        margin-left: auto;
        margin-top: 0;
      }

      & > * {
        &:not(:last-child) {
          margin-bottom: 20px;

          @include breakpoint(md) {
            margin-right: 20px;
            margin-bottom: 0;
          }
        }
      }
    }
  }

  /* stylelint-disable */
  &__button-wrapper {
    display: flex;

    &--right {
      justify-content: flex-end;
    }

    & > * {
      &:not(:last-child) {
        margin-right: 20px;
      }
    }
  }
  /* stylelint-enable */
}

#registrierungsformular {
  height: 100%;
}
