.contact-form {
  display: flex;
  flex-direction: column;
  width: 100%;

  @include breakpoint(md) {
    width: 50%;
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  &__button {
    align-self: flex-end;
  }

  &__headline {
    width: 100%;

    @include breakpoint(md) {
      width: 50%;
    }
  }
}
