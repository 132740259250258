.info-box {
  margin-top: 50px;
  border-radius: 12px;
  background-color: $background-grey;
  box-shadow: $box-box-shadow;
  padding: 30px;

  &__headline {
    font-weight: bold;
    margin-bottom: 20px;
  }

  &__table {
    display: grid;
    grid-template-columns: repeat(2, fit-content(50%));
    grid-gap: 10px 20px;
  }

  &__text {
    width: auto;
  }

  &__link {
    display: block;
    margin-top: 20px;
  }
}
