$white: #ffffff;
$black: #000000;

$green-dark: #31520c;
$green-light: #588e19;

$grey: #707070;
$background-grey: #f8f8f8;
$border: #e0e0e0;

$font-color: #2d2d2d;
