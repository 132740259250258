.footer {
  &-main {
    border: solid $border;
    border-width: 1px 0;

    &__container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 25px;

      @include breakpoint(lg) {
        padding-top: 30px;
        padding-bottom: 30px;
      }
    }

    &__logo {
      &--small {
        height: 50px;
      }

      &--big {
        height: 45px;

        @include breakpoint(lg) {
          height: 60px;
        }
      }

      &--hide-mobile {
        display: none;

        @include breakpoint(lg) {
          display: inherit;
        }
      }
    }
  }

  &-legal {
    padding: 25px;

    @include breakpoint(lg) {
      padding: 20px 0;
    }

    &__container {
      display: flex;
      padding: 0;

      @include breakpoint(lg) {
        justify-content: flex-end;
        padding-right: 25px;
      }
    }

    &__link {
      color: $grey;

      &:not(:last-child) {
        margin-right: 40px;
      }
    }
  }
}
