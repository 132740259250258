.facts {
  background-image: url("/images/lhv/backgrounds/lines.png");
  background-size: 30px;
  padding: 50px 0;

  @include breakpoint(md) {
    background-size: 40px;
    padding: 100px 0;
  }

  &__container {
    display: grid;
    grid-template-columns: 1fr;

    @include breakpoint(md) {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &-item {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__number {
      color: $green-dark;
      font-size: 50px;
      font-weight: bold;
      margin-bottom: 0;

      @include breakpoint(sm) {
        font-size: 70px;
      }

      @include breakpoint(lg) {
        font-size: 90px;
      }
    }

    &__text {
      color: $green-light;
      font-size: $m-font-sm;
      margin-bottom: 20px;

      @include breakpoint(md) {
        font-size: 24px;
        margin-bottom: 0;
      }
    }
  }
}
