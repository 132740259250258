.button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 32px;
  font-size: 16px;
  text-transform: uppercase;
  height: 50px;
  border-radius: 12px;
  background-color: #588e19;
  color: white;
  border: #588e19 2px solid;
  cursor: pointer;

  &__text {
    &--hidden-mobile {
        display: none;

        @include breakpoint(md) {
            display: block;
        }
    }
  }

  &__icon {
    margin-left: 25px;

    &--loading {
      animation: rotate;
      animation-duration: 1.3s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;

      @keyframes rotate {
        from {
          transform: rotate(0);
        }

        to {
          transform: rotate(360deg);
        }
      }
    }
  }

  &--ghost-white {
    background-color: transparent;
    color: white;
    border: white 2px solid;

    .button {
      &__text {
        color: white;
      }
    }
  }

  &--ghost-green {
    background-color: transparent;
    color: #588e19;
    border: #588e19 2px solid;

    .button {
      &__text {
        color: #588e19;
      }
    }
  }

  &--ghost-borderless {
    background-color: transparent;
    color: #588e19;
    border: none;

    .button {
      &__text {
        color: #588e19;
      }
    }
  }

  &--text-hidden-mobile {
    .button {
      &__text {
        display: none;

        @include breakpoint(md) {
          display: block;
        }
      }

      &__icon {
        @include breakpoint(md-max) {
          margin-left: 0;
        }
      }
    }
  }
}
