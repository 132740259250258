@font-face {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("/media/fonts/DMSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "DM Sans";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("/media/fonts/DMSans-Italic.ttf") format("truetype");
}

@font-face {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("/media/fonts/DMSans-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "DM Sans";
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url("/media/fonts/DMSans-MediumItalic.ttf") format("truetype");
}

@font-face {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("/media/fonts/DMSans-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "DM Sans";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("/media/fonts/DMSans-BoldItalic.ttf") format("truetype");
}

* {
  font-family: "DM Sans", sans-serif;
  color: $font-color;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  font-size: $font-s;
}
