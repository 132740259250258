.content-box {
  background: white;

  &__container {
    display: grid;
    grid-template-columns: 1fr;
    box-shadow: $box-box-shadow;
    border-radius: 16px;
    background-color: $background-grey;

    @include breakpoint(md) {
      grid-template-columns: 1fr 2fr;
    }

    @include breakpoint(lg) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &__image-wrapper {
    display: flex;

    picture {
      width: 100%;
    }
  }

  &__image {
    border-radius: 16px 16px 0 0;
    height: 100%;
    max-height: 300px;
    max-width: 100%;
    object-fit: cover;

    @include breakpoint(md) {
      border-radius: 16px 0 0 16px;
      max-height: none;
      width: 100%;
    }
  }

  &__headline {
    font-size: $m-font-m;

    @include breakpoint(md) {
      font-size: $font-m;
    }
  }

  &__text {
    p {
      font-size: $m-font-s;
      color: #707070;

      @include breakpoint(md) {
        font-size: $font-s;
      }

      &:not(:last-child) {
        margin-bottom: 25px;
      }
    }
  }

  &__content {
    padding: 25px;

    @include breakpoint(xl) {
      padding: 50px;
    }
  }

  &__button-wrapper {
    display: flex;
    flex-flow: column;
    margin-top: 40px;

    @include breakpoint(md) {
      flex-flow: wrap;
      margin-top: 80px;
    }
  }

  &__button {
    justify-content: center;

    &:not(:last-child) {
      margin-bottom: 20px;

      @include breakpoint(md) {
        margin-right: 20px;
      }
    }
  }

  &-download {
    &__title {
      margin-left: 20px;
    }

    &__item {
      display: flex;
      align-items: center;

      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }

  &--reverse {
    .content-box {
      &__container {
        @include breakpoint(md) {
          grid-template-columns: 2fr 1fr;
          max-width: none;
        }

        @include breakpoint(lg) {
          grid-template-columns: repeat(2, 1fr);
        }
      }

      &__image {
        @include breakpoint(md) {
          border-radius: 0 16px 16px 0;
        }
      }

      &__content {
        @include breakpoint(md) {
          grid-row: 1;
        }
      }
    }
  }
}
