ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: grid;
  gap: 10px;

  li {
    display: grid;
    grid-template-columns: 0 1fr;
    gap: 30px;
    align-items: start;

    &::before {
      content: "";
      width: 7px;
      height: 7px;
      border-radius: 100%;
      background-color: $green-light;
      align-self: start;
      margin: 6px;
    }
  }
}
