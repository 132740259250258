.four-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 40px;

  &__item {
    &--center {
      place-self: center;
    }
  }
}
