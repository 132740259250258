.contact {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 50px;

  @include breakpoint(md) {
    grid-template-columns: 1fr 1fr;
  }

  @include breakpoint(lg) {
    grid-template-columns: 2fr 1fr;
  }

  @include breakpoint(xl) {
    grid-column-gap: 100px;
  }

  &__image {
    width: 100%;
    max-width: 100%;
    max-height: 300px;
    object-fit: cover;
    margin-bottom: 25px;

    @include breakpoint(md) {
      max-height: none;
      margin-bottom: 0;
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: fit-content(50%) fit-content(50%);
    grid-column-gap: 20px;
}

  &__content div:last-child {
    margin-bottom: 0;
  }

  &__container {
    margin-bottom: 20px;
  }

  &__main-headline {
    margin-bottom: 20px;
  }

  &__content-headline {
    font-weight: bold;
  }
}
