.ansprechpartner {
  display: grid;
  //grid-template-columns: 100px 1fr;
  grid-column-gap: 30px;
  align-items: center;

  @include breakpoint(xl) {
    grid-template-columns: 1fr;
  }

  &__text-wrapper {
    margin-top: 10px;
  }

  &__wrapper {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;

    @include breakpoint(sm) {
      grid-template-columns: repeat(3, 1fr);
    }

    @include breakpoint(lg) {
      grid-gap: 75px;
    }
  }

  &__image {
    width: 100%;
    height: auto;

    @include breakpoint(xl) {
      margin-bottom: 15px;
    }
  }

  &__name {
    margin-bottom: 4px;
  }

  &__position {
    font-size: 12px;
  }
}
