.container {
  margin: 0 auto;
  width: 100%;
  padding: 0 20px;
  max-width: 680px;

  @include breakpoint(md) {
    max-width: 1400px;
    padding: 0 40px;
  }

  &--small {
    max-width: 1000px;
  }

  &--full-height {
    height: 100%;
  }

  &--mobile-full-width {
    @include breakpoint(md-max) {
      max-width: none;
    }
  }
}
