.input {
  border-radius: 12px;
  border: solid $border 1px;
  height: 50px;
  padding: 10px 20px;
  width: 100%;
  outline-color: $green-light;

  &:focus,
  &:active {
    border: solid $green-light 1px;
  }

  &[readonly] {
    background-color: $background-grey;
    color: $grey;
    outline: none;
  }
}
