.multi-select {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-column-gap: 20px;

  @include breakpoint(md) {
    grid-template-columns: repeat(2, 1fr);
  }

  &__option {
    display: flex;
    align-items: center;
    padding: 0 32px;
    font-size: 16px;
    font-weight: bold;
    height: 50px;
    border-radius: 12px;
    border: $background-grey 2px solid;
    background-color: $background-grey;
    color: $grey;
    cursor: pointer;
    margin-bottom: 20px;
  }

  &__input {
    position: absolute;
    visibility: hidden;

    &:checked + label {
      border: #588e19 2px solid;
      color: $green-dark;
    }
  }

  &__error {
    margin-top: 20px;
    grid-column: 1/3;
  }

  &--yesno {
    display: flex;
    flex-direction: column;

    .multi-select {
      &__option-wrapper {
        display: flex;
      }

      &__yesno-wrapper {
        &:not(:last-child) {
          margin-right: 20px;
        }
      }

      &__option {
        text-align: center;
      }
    }
  }
}
