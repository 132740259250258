.textarea {
  border-radius: 12px;
  border: solid $border 1px;
  padding: 10px 20px;
  resize: vertical;
  width: 100%;
  outline-color: $green-light;

  &:focus,
  &:active {
    border: solid $green-light 1px;
  }
}
