$buttonWidth: 30px;
$buttonHeight: 16px;
$buttonColor: black;
$lineThickness: 2px;
$transitionSpeed: 0.25s;
$transitionEasing: ease-in-out;

.button-toggle {
  position: relative;
  display: block;
  width: $buttonWidth;
  height: $buttonHeight;
  background: transparent;
  border-top: $lineThickness solid;
  border-bottom: $lineThickness solid;
  color: $buttonColor;
  font-size: 0;
  transition: all $transitionSpeed $transitionEasing;

  &::before,
  &::after {
    content: " ";
    display: block;
    width: 100%;
    height: $lineThickness;
    position: absolute;
    top: 50%;
    left: 50%;
    background: currentcolor;
    transform: translate(-50%, -50%);
    transition: transform $transitionSpeed $transitionEasing;
  }

  &.is-active {
    border-color: transparent;

    &::before {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &::after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
}

/* .menu-button {
  display: flex;
  height: 16px;
  flex-flow: column;
  justify-content: space-between;

  &__line {
    width: 30px;
    height: 2px;
    background-color: black;
  }
} */
